import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { Outlet } from "react-router-dom";

const ProtectedRoute: React.FC = () => {
  // const navigate = useNavigate();
  // useEffect(() => {
  //   validate().then((response) => {
  //     if (!response) {
  //       navigate("/email-verification");
  //     }
  //   });
  // }, [navigate]);
  const location = useLocation();
  useEffect(() => {
    // Send the current route to the parent window (WordPress)
    window.parent.postMessage(
      { type: "routeChange", route: location.pathname },
      "*"
    );
  }, [location]);

  return <Outlet />;
};

export default ProtectedRoute;
