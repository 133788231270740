import React from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { Plan } from "./Plans";

const PayPalCheckout: React.FC<{
  plan: Plan;
  onPostcheckout: (arg: any) => void;
}> = ({ plan, onPostcheckout }) => {
  const initalOptions = {
    clientId:
      "AbIbYLdMHCx0ctFXcXicOEyLhvFSgr4OrmZdWpTISdbj-Q_JxmUx3Ngewvlg7ptjtJQWzcWhMQ6CoeRa",
    currency: "USD",
    intent: "capture",
  };

  const createOrder = (data: any, actions: any) => {
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: plan.price.replace("$", ""),
            currency_code: "USD",
          },
        },
      ],
    });
  };

  const onApprove = (data: any, actions: any) => {
    return actions.order.capture().then((details: any) => {
      alert("Transaction completed by " + details.payer.name.given_name);
      onPostcheckout(details);
    });
  };
  return (
    <PayPalScriptProvider options={initalOptions}>
      <PayPalButtons
        createOrder={(data, actions) => {
          return createOrder(data, actions);
        }}
        onApprove={(data, actions) => {
          return onApprove(data, actions);
        }}
        onCancel={(data) => {
          alert("Payment cancelled. Please try again.");
        }}
        onError={(err) => {
          alert("An error occurred during the transaction.");
          console.error("PayPal Checkout Error:", err);
        }}
      />
    </PayPalScriptProvider>
  );
};

export default PayPalCheckout;
