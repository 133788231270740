import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import ListingTable from "./ListingTable";
import ReactListingTable from "./UpdatedReactListing";
import Plans from "./Plans";
import PayPalCheckout from "./PaypalCheckout";

const ResultPage: React.FC = () => {
  const location = useLocation();
  const { resOpps, resTotalCount } = location.state;
  const [opps, setOpps] = useState<any>([]);
  const [totalCount, setTotalCount] = useState<number>(0);

  // useEffect(() => {
  //   //Fetch results based on selected keywords
  //   const fetchResults = async () => {
  //     const token = localStorage.getItem("token");
  //     const axiosConfig = {
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${token}`,
  //       },
  //     };
  //     try {
  //       const response = await axios.get(
  //         `${
  //           process.env.REACT_APP_API_URL
  //         }/demo/opportunities?website=${websiteUrl}&${selectedKeywords
  //           .map((keyword: any) => `keywords=${keyword}`)
  //           .join("&")}`,
  //         axiosConfig
  //       );
  //       setOpps(response.data.opps);
  //       setTotalCount(response.data.count);
  //     } catch (error) {
  //       console.error("Error fetching results:", error);
  //     }
  //   };

  //   if (selectedKeywords.length > 0) {
  //     fetchResults();
  //   }
  // }, [selectedKeywords]);

  useEffect(() => {
    console.log("Results", resOpps, resTotalCount);
    setOpps(resOpps);
    setTotalCount(resTotalCount);
  }, [resOpps, resTotalCount]);
  return (
    <div className="min-h-screen flex flex-col items-center justify-center">
      <h1 className="text-2xl font-bold mb-6 text-center">Results</h1>
      <div>
        <ReactListingTable opps={opps} totalCount={totalCount} />
      </div>
    </div>
  );
};

export default ResultPage;
