import axios from "axios";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const ResultLoading: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { selectedKeywords } = location.state || { selectedKeywords: [] };
  const websiteUrl = location.state.websiteUrl;

  useEffect(() => {
    //Fetch results based on selected keywords
    const fetchResults = async () => {
      const token = localStorage.getItem("token");
      const axiosConfig = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      try {
        const response = await axios.get(
          `${
            process.env.REACT_APP_API_URL
          }/demo/opportunities?website=${websiteUrl}&${selectedKeywords
            .map((keyword: any) => `keywords=${keyword}`)
            .join("&")}`,
          axiosConfig
        );
        console.log(response.data.opps, response.data.count);
        navigate("/results", {
          state: {
            resOpps: response.data.opps,
            resTotalCount: response.data.count,
          },
        });
      } catch (error) {
        console.error("Error fetching results:", error);
      }
    };

    if (selectedKeywords.length > 0) {
      fetchResults();
    }
  }, [selectedKeywords]);

  return (
    <div className="min-h-screen flex flex-col items-center justify-center">
      {selectedKeywords.length === 0 ? (
        <h2 className="text-xl text-center text-red-500">
          No keywords were selected.
        </h2>
      ) : (
        // <h2 className="text-xl text-center">Loading...</h2>
        <div className="flex justify-center items-center">
          <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-blue-500"></div>
        </div>
      )}
    </div>
  );
};

export default ResultLoading;
