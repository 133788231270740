import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ProgressWebSocket from "./ProgressWebSocket";

const ScrapingProgress: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [keywords, setKeywords] = useState<string[]>([]);
  const { spiderId, websiteUrl } = location.state;
  const [scrapingInProgress, setScrapingInProgress] = useState<boolean>(true);

  useEffect(() => {
    // Fetch keywords from backend using the key
    const fetchKeywords = async () => {
      const token = localStorage.getItem("token");
      const axiosConfig = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/demo/keywords?spiderId=${spiderId}`,
          axiosConfig
        );
        setKeywords(response.data.keywords);
      } catch (error) {
        console.error("Error fetching keywords:", error);
      }
    };
    if (scrapingInProgress) {
      return;
    }
    fetchKeywords();
  }, [scrapingInProgress]);

  useEffect(() => {
    if (!scrapingInProgress) {
      navigate("/keywords", {
        state: { websiteUrl, fetchedKeywords: keywords },
      });
    }
  }, [keywords]);

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <ProgressWebSocket
        spiderId={spiderId}
        setScrapingInProgress={setScrapingInProgress}
      />
    </div>
  );
};

export default ScrapingProgress;
